import React from 'react';
import ReactDOM from 'react-dom';
import { AlertModal } from '@/src/shop/shared/components/AlertModal';

document.addEventListener('DOMContentLoaded', () => {
  const alertModalElement = document.getElementById('j-alert-modal');
  if (alertModalElement) {
    const messages: string[] = JSON.parse(alertModalElement.dataset.messages || '[]');
    const title = alertModalElement.dataset.title;
    ReactDOM.render(<AlertModal title={title} messages={messages} />, alertModalElement);
  }
});
