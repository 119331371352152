/** @jsx jsx */

import { FC, useState, useCallback } from 'react';
import Modal from 'react-bootstrap/cjs/Modal';
import Button from 'react-bootstrap/cjs/Button';
import { jsx, css } from '@emotion/core';

type Props = {
  title?: string;
  messages: string[];
};

const titleStyle = css`
  color: red;
  font-weight: bold;
  font-size: 1.2rem;
`;

export const AlertModal: FC<Props> = ({ title = '処理に失敗しました', messages }: Props) => {
  const [show, setShow] = useState(true);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title css={titleStyle}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          {messages.map((message, index) => {
            return <li key={index}>{message}</li>;
          })}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
